<script setup>
import Checkbox from '@/Components/Checkbox.vue';
import CheckboxLabel from '@/Components/CheckboxLabel.vue';
import InputLabel from '@/Components/InputLabel.vue';
import TextInput from '@/Components/TextInput.vue';
import { Head, Link, useForm, usePage } from '@inertiajs/vue3';
import LoginLink from '@/../../vendor/spatie/laravel-login-link/resources/js/login-link.vue';
import SimpleLayout from '@/Layouts/SimpleLayout.vue';

defineOptions({
    layout: SimpleLayout,
});

defineProps({
    canResetPassword: {
        type: Boolean,
    },
    status: {
        type: String,
    },
});

const environment = usePage().props.config.environment;

const form = useForm({
    email: '',
    password: '',
    remember: false,
});

const submit = () => {
    form.post(route('login'), {
        onFinish: () => form.reset('password'),
    });
};
</script>

<template>
    <Head :title="$t('auth.login')" />

    <h1 class="mb-12 heading-1">{{ $t('auth.login') }}</h1>

    <form class="mb-6 space-y-6" @submit.prevent="submit">
        <InputLabel :error="form.errors.email">
            <TextInput
                v-model="form.email"
                name="email"
                type="email"
                autofocus
                autocomplete="username"
                :placeholder="$t('auth.email')"
                :has-error="!!form.errors.email"
            />
        </InputLabel>

        <InputLabel :error="form.errors.password">
            <TextInput
                v-model="form.password"
                name="password"
                type="password"
                autocomplete="current-password"
                :placeholder="$t('auth.password')"
                :has-error="!!form.errors.password"
            />
        </InputLabel>

        <CheckboxLabel
            :label="$t('auth.remember_me')"
            :error="form.errors.remember"
        >
            <Checkbox
                v-model:checked="form.remember"
                name="remember"
                class="checkbox-primary"
                :has-error="!!form.errors.remember"
            />
        </CheckboxLabel>

        <button class="btn-block btn btn-primary" :disabled="form.processing">
            {{ $t('auth.login') }}
        </button>
    </form>

    <Link
        v-if="canResetPassword"
        :href="route('password.request')"
        class="block link link-neutral"
    >
        {{ $t('auth.forgot_password') }}?
    </Link>

    <div class="mt-12 text-neutral">
        {{ $t('auth.no_account_yet') }}
        <Link :href="route('register')" class="link">
            {{ $t('auth.register_now') }}
        </Link>
    </div>

    <div v-if="environment === 'local'" class="mt-16">
        <LoginLink email="admin@dotbite.at" label="Login as admin@dotbite.at" />
        <LoginLink email="user@dotbite.at" label="Login as user@dotbite.at" />
    </div>
</template>
